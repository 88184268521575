.color-wrapper {
    background-color: #e0dede;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
    color: #000;
    border-radius: $border-radius;
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;

    .vehicle-color-list-White{
        background-color: #FFF;
    }

    svg {
        height: 12px;
        margin-top: 2px;
        margin-bottom: 5px;
        margin-bottom: -2px;
    }
}

.mng-vehicle-list-container{
    .ant-list-item {
       border-block-end: 1px solid rgba(5, 5, 5, 0.2) !important;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex-separate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-table-column-sort {
  background-color: transparent !important;
}

/* Adding this at root level since we want to have all the content on the same line */
.ant-table-cell{
  vertical-align: baseline;
}
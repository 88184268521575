.lead-card {
  background-color: #ffffff;
  padding: 16px; // Adjust as needed
  border-radius: 12px; // Adjust as needed
  border-color: #5d5d5d;
  border-style: solid;
  border-width: 1;
  // max-width: 380px;
  margin: 5px 0;
  cursor: pointer;
}

.lead-view-container {
  background-color: #F5F8FA;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px; // Assuming rfv() is a function that calculates relative values
  padding: 16px;
  display: flex;
}

.leads-vehicle-count {
  background-color: #c6e4ff;
  display: flex;
  height: 40px;
  flex-direction: row;
  padding: 10px 15px; // Adjust as needed
  align-items: center;
  gap: 10px; // Requires CSS Grid or Flexbox gap support
  border-radius: 10px; // Adjust as needed
}

.lead-vehicle-count {
  color: 'black';
  font-size: 14px;
}


.leads-address {
  display: flex;
  align-items: center;
}

.address-image {
  height: 40px;
  width: 40px;
}

.address-detail {
  padding-left: 12px;
}

.address-label {
  color: #828282;
  font-size: 14px;
}

.address-text {
  color: #101010;
  font-size: 16px;
  flex-wrap: wrap;
}

.address-separator {
  margin-left: 20px;
}

.address-separator-dots {
  background-color: #aaaaaa;
  height: 2px;
  width: 2px;
  border-radius: 2px;
  margin-bottom: 2px;
}

// Date and time Component

.leads-date-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  gap: 12px
}

.item-wrapper {
  display: flex;
  height: 50px;
  align-items: center;
  border: 1px solid #EBEBEB;
  gap: 2px;
  border-radius: 50px;
  padding: 7px 10px;
  width: 100%;

}

.date-view {
  margin: 0 3px;
  height: 35px;
}

.item-text {
  color: #101010;
  font-size: 12px;
  justify-content: center;
}


//   Text

.value-text {
  padding-top: 6px;
  font-size: 13px;
  color: $limo-lead-detail-text;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.text-header {
  margin-top: 12px;
  font-size: 15px;
  color: '#101010';
  font-weight: 600;
}

.read-more-text {
  padding-top: 20px;
  font-weight: 600;
  font-size: 13px;
  color: $limo-color;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

// Vehicle Card
.vehicle-quote-container {
  border-color: #eaeaea;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
}

.vehicle-info-container {
  flex: 1;
  padding-left: 12px;
}

.vehicle-category-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.hired-vehicle-ic-container {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4ed293;
  margin-left: 5px;
}

.vehicle-category-text {
  font-size: 16px;
  color: #101010;
}

.vehicle-spec-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  padding-top: 8px;
}

.vehicle-single-spec {
  display: flex;
  align-items: center;
  gap: 4px;
}

.spec-color {
  border-width: 1px;
  border-radius: 12px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-color-text {
  font-size: 4px;
  color: #5d5d5d;
}

.spec-text {
  font-size: 16px;
  color: #101010;
}

.quote-section {
  padding-top: 8px;
}

.quote-status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.quote-status-text {
  font-size: 14px;
  color: #fb3737;
  flex: 1;
}

.quote-price-btn {
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  padding: 0 8px;
  border-radius: 8px;
  background-color: #101010;
  align-items: center;
}

.quote-price-btn-text {
  font-size: 12px;
  line-height: 16px;
  margin-top: 0;
}

.quote-price-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.single-quote-price-box {
  background-color: #f1f9ff;
  border-radius: 8px;
  padding: 4px 10px;
  display: inline-block;
  margin: 0 4px;
}

.quote-price-text {
  font-size: 14px;
  color: #0579dc;
}

.quote-by-container {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;
}

.quote-by-text {
  font-size: 12px;
  color: #5d5d5d;
}

.quote-time {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;

  svg {
    color: #5d5d5d;
  }
}

// Lead Detail CSS
.singleAttr-container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.5rem;
}

.icon-container {
  background-color: #FFFFFF;
  border-color: #EBEBEB;
  border-width: 1px;
  border-radius: 12px;
  height: 43px;
  width: 43px;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.label-text {
  font-size: 14px;
  color: #5D5D5D;
}

.approx-text {
  font-size: 14px;
  color: black;
}

.value-text {
  gap: 12px;
  font-size: 14px;
  color: #101010;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

.noWrap {
  flex: none;
}

.horizontal-detail-container {
  flex-direction: row;
  padding-top: 16;
}

.status-label {
  padding: 12px 4px;
  border-radius: 8px;
  height: 27px;
  width: 72px;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  display: flex;
}

.masonry-container {
  max-width: 1920px;
  margin: auto;
  columns: 3 300px;
  column-gap: 1rem;
}

.masonry-item {
  margin: 0 0 5px;
  display: inline-block;
  width: 100%;
  break-inside: avoid;
}

.closed-box {
  border-color: #FB3737;
  border-width: 1px;
  border-style: solid;
  margin: 10px;
  padding: 0.3rem;
  width: auto;
  align-items: center;
  justify-content: center;
}

.sent-quote-card .value-text{
  padding-bottom: 10px;
}
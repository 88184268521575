.insurance-icon {
  font-size: 18px;
}

.affiliate-id {
  font-size: 20px;
  border: 2px solid #c1c1c1;
  padding: 10px;
  text-align: right;
  float: right;
  margin-top: 0;
}

.contact-icon {
  font-size: 15px;
}

.vehicle-action-icon {
  font-size: 15px;
  cursor: pointer;
  margin: 0 5px;

  :hover {
    color: $limo-color;
  }
}

.address-container {}

.service-detail-container {
  flex: 1;
}

.status-container {
  margin-left: 10px;
}

.note-detail-container {
  flex: 1;
}

.address-detail-container {
  flex: 1;
  margin-left: 20px;
}

.approval-detail-container {
  flex: 1;
  margin-left: 20px;
}

.contact-details-container {
  flex: 1;
}

.affiliate-color {
  text-transform: capitalize;
}

.affiliate-status-tag {
  text-transform: capitalize;
  width: 160px !important;

  .ant-select-selection-search-input {
    display: none;
  }
}

.affiliate-details-container {
  .timeline-container {
    max-height: 250px !important;
  }
}

.lead-details-container {

  padding-bottom: 30px !important;

  .p-ant-table-container {
    padding: 20px 5px;
  }

  .vehicle-table .ant-table {
    border-radius: 8px;
  }

  .contact-table {
    padding-top: 0;
  }

  .contact-table .ant-table {
    border-radius: 8px;
  }

  .company-name {
    font-size: 25px;
    font-weight: 700;
    padding-left: 0;
    text-overflow: ellipsis;
    display: inline-block;
    width: 80%;
  }

  .ant-input-disabled,
  .ant-select-disabled .ant-select-selection-item,
  .ant-input-number-input {
    color: rgba(0, 0, 0, 0.88);
  }

  .ant-picker-input input {
    color: rgba(0, 0, 0, 0.88) !important;
  }

  .website-link-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-input-affix-wrapper {
    padding: 4px 0;
  }
}
.add-affiliate-container {
    padding: 0px;
    min-height: 100vh;


    .add-affiliate-section {
        background-color: $white-color;
        padding: 20px;
        margin: 10px 15px 0 15px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
    }

    .add-affiliate-form-item {
        margin: 10px 0;
    }

    .add-affiliate-form-item-label {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 600;
    }
}

.black-bg{
    background-color: #000;
    padding-bottom: 10px;
    
    h1, h4, h5, h6 {
        color: #FFF;
        margin-top: 0;
        padding-top: 10px;
        margin-left: 15px;
    }
}

.vehicle-select-list-container {
    width: 100%;
    height: 70px;

    .ant-select-selection-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.vehicle-color-list-container {
    width: 100%;
}

.vehicle-passenger-list-input {
    width: 100%;
}

.vehicle-select-list-item .ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vehicle-select-list {
    height: 50px;
    width: 100px;
    background-repeat: no-repeat, no-repeat !important;
    background-position: center left !important;
    background-size: 80px auto !important;
    display: inline-block;
}

.vehicle-color-list-icon {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
}

.vehicle-list-color-icon{
    height: 10px;
    width: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
}

.vehicle-color-list-Black {
    background-color: black;
}

.vehicle-color-list-White {
    background-color: rgb(241, 237, 237);
}

.vehicle-color-list-Grey {
    background-color: grey;
}

.vehicle-color-list-Red {
    background-color: red;
}

.vehicle-color-list-Pink {
    background-color: pink;
}

.vehicle-color-list-Yellow {
    background-color: yellow;
}

.vehicle-color-list-Other {
    border: 1px dotted black !important;
}

.vehicle-color-black-border {
    border: 1px solid #000000;
}

.vehicle-color-non-black-border {
    border: 1px solid #FFFFFF;
}

.company-detail-container{
    padding: 15px;
}

.affiliate-contact-container{
    .ant-alert-info {
        .ant-alert-message, .ant-alert-description {
            color: #FFF;
        }
    }
}

.alert-section{
    background: #000000;
}

.alert-section-btn{
    background: #ffffff;
    color: #000000;
}
.auth-wrapper {
  display: flex;
  height: 100vh;
}

.auth-section {
  width: 100%;
  height: 100vh;

  .ant-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 50px;

    .mb-3 {
      width: 100%;
      margin: 10px 0;

      label,
      p {
        font-size: 18px;
        margin: 10px 5px;
      }
    }
  }
}

.limo-img-wrapper img {
  height: 100vh;
  object-fit: cover;
}

.login-logo-wrapper {
  text-align: center;
  margin: 0 auto;
}
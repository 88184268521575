.home-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
  background: rgb(248,252,255);
  background: -moz-radial-gradient(circle, rgba(248,252,255,1) 0%, rgba(223,240,255,1) 35%);
  background: -webkit-radial-gradient(circle, rgba(248,252,255,1) 0%, rgba(223,240,255,1) 35%);
  background: radial-gradient(circle, rgba(248,252,255,1) 0%, rgba(223,240,255,1) 35%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8fcff",endColorstr="#dff0ff",GradientType=1);
}

.home-logo-wrapper {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

.home-extra-logo{
  text-align: center;
  margin: 0 auto;
}

.home-btn-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  button{
    margin: 0 10px;
  }
}

.w3-animate-bottom{
  position:relative;
  animation:animatebottom 0.8s
}

@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}

.w3-animate-top{position:relative;animation:animatetop 0.8s}

@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}

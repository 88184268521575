.quote-price-input {
    flex: 1;
}

.quote-price-label{
    flex: 1;
}

.quote-price-label.round-trip{
    font-size: 12px;
    font-weight: 700;

    p {
        font-size: 10px;
        margin-bottom: 7px;
    }
}

.vehicle-check-image{
    text-align: right;
}

.quote-price-modal-container{
    ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }
    
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: rgba(#FFF, .2);
        border: 3px solid transparent;
        border-radius: $border-radius;
    }

    .nmv-container { 
        max-height: 300px;
        overflow-y: scroll; 
    }    
}
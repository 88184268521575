.lead-details-container {
  padding: 0px;

  .lead-detail-section {
    background-color: $white-color;
    padding: 20px;
    margin: 0 250px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    // Sent to agent dropdown adjustment
    .flex-1 {
      margin-right: 10px;
    }

    // Doing a workaround to change the tag color for unread which was white 
    // by default making it unreadable
    .lead-status-tag-unread .ant-tag{
      color: #d4b106;
      background: #feffe6;
      border-color: #fffb8f;
    }

    
    @media only screen and (max-width: 1076px) {
      margin: 0 15px;
    }
  }

  .customer-link-section {
    background-color: #fff7e6;
    padding: 20px;
    margin: 20px 15px 0 15px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    h3, div {
      color: #d46b08;
    }
  }

  .send-link-container {
    display: flex;
    flex-direction: column;
    margin-top: -22px;
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    label {
      padding-bottom: 10px;
    }

    input {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .btn-copy {
    margin-right: 10px;
  }

  .lead-bottom-btn {
    margin-left: auto;
  }

  .timeline-icon {
    font-size: 16px;
  }

  .timeline-container {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 9px; //scrollbar width

    .activity-quote-info{
      font-size: 12px;
      margin: 3px 0;
    }
  }

  .search-section{
    font-size: 10px;
    font-style: italic;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(#333, .2);
    border: 3px solid transparent;
    border-radius: $border-radius;
  }

  .lead-vehicle-img {
    height: 50px;
    width: 125px;
    background-repeat: no-repeat, no-repeat !important;
    background-position: center right !important;
    background-size: 80px auto !important;
    display: inline-block;
  }
}

.ld-passenger svg{
  height: 12px;
  display: inline-block;
}

.ld-matching-vehicle{
  border-radius: 10px;
  margin: 6px 0;
  border: 1px solid rgba(5, 5, 5, 0.2);

  .ant-list-item-meta{
    justify-content: center;
    align-items: center !important;
  }
}

.ld-matching-vehicle-list{
  padding: 0 10px;

  .ant-list-item, .ant-list-item:last-child{
    border-block-end: 1px solid rgba(5, 5, 5, 0.2);
  }

  .ld-vehicle-desc{
    font-size: 14px;
    color: #333;
    margin: 0;

    svg {
      height: 14px;
      margin-bottom: -2px;
    }
  }

  .ld-badge{
    height: 40px;
    width: 5px;
    display: inline-block;
    background-color: #FFF;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  
  .ant-list-item.available {
    // background-color: #418ccd4d;
    border: 1px solid #0579dc;
    border-block-end: 1px solid #0579dc;

    .ld-badge{
      background-color: #0579dc;
    }
  }
  

  .ant-list-item.not-interested {
    background-color: #e4e5e269;
    
    .ld-badge{
      background-color: rgba(5, 5, 5, 0.2);
    }
  }

}
// Constants
$limo-color: #418CCD;
$white-color: #FFF;
$border-radius: 10px;
$box-shadow: #00000033 0px 4px 6px -1px;
$limo-vehicle-color:#a9dded;

$gallery: #ebebeb;
$porcelain: #eceef0;
$alto: #d9d9d9;
$ghost: #c1c5ce;
$manatee: #90959e;
$nevada: #707172;
$nepal: #93a0be;
$comet: #515b73;
$mineShaft: #343434;
$limo-lead-detail-text: #5D5D5D;